.header {
    background-color: #fff;
    padding: 20px 15px;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, .2);
    z-index: 10;

    .navigation__list {
        gap: 30px;
    }

    .navigation__link {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
    }

    .header__container {
        gap: 60px;
        justify-content: flex-start;
    }

    .header__logo {
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 600;
    }

    .header__slogan {
        letter-spacing: 1.3px;
        font-size: 14px;
    }

    .header__product {
        margin: 0 0 0 auto;
    }

    .header__product-link {
        background-color: var(--color-accent);
        color: var(--color-white);
        border-radius: 50px;
        padding: 10px 24px;
    }
}
