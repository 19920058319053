.about-us{
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--color-background);
}

.about-us__container-top{
    display: flex;    
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.about-us__text-container{
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    .about-us__title{
        max-width: 480px;
        text-align: start;
    }
}

.about-us__text{
    max-width: 480px;
}

.about-us__title{    
    color: var(--color-title);
    font-size: 60px;
    font-weight: 500;
    width: 100%;    
}

.about-us__img-container{
    width: 45%;    
}

.about-us__img{
    width: 100%;
    height: 100%;
}

.about-us__container-bottom{
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;

    .about-us__title{
        max-width: 400px;
        text-align: start;
        line-height: 1.1;
    }
}

.about-us__list{
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style-type: disc;
    padding-left: 8px;
}