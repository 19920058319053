html {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
}

body {
    position: relative;
    overflow: auto;
    color: var(--color-text);
    background-color: var(--color-white);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
}

.container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 15px;
}

.full-width {
    width: 100%;
    max-width: 100%;
}

.img-container {
    width: 100%;
}

.img-container img {
    width: 100%;
    height: 100%;
}

.title {
    text-align: center;
    font-size: 30px;
    color: var(--color-title);
    font-weight: 500;
}

.subtitle {
    text-transform: uppercase;
    color: var(--color-subtext);
    font-weight: 600;
    font-size: 14px;
}

.container__vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container__horizontal {
    display: flex;
    align-items: center;
}