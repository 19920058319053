.footer {
    background-color: var(--color-background);
    box-shadow: 0 -1px 6px -1px rgba(0, 0, 0, .2);
    padding: 30px 0;

    .footer__container {
        align-items: flex-start;
        gap: 30px;
    }

    .footer__logo-container {
        flex: 2;
    }

    .footer__logo {
        font-size: 34px;
        color: var(--color-title);
        font-weight: 600;
    }

    .footer__navigation {
        flex: 2;
        padding-left: 10px;
    }

    .navigation__list {
        gap: 5px;
    }

    .navigation__title {
        color: var(--color-title);
        margin-bottom: 10px;
        font-size: 15px;
    }

    .navigation__link {
        width: fit-content;
        font-size: 14px;
    }

    .footer__copyright {
        font-size: 12px;
    }
}