.product {
    padding: 30px 0;
}

.product__buy {
    background-color: var(--color-accent);
    color: var(--color-white);
    border-radius: 50px;
    padding: 10px 24px;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
}
.product__info-container {
    gap: 10px;
    margin-top: 15px;
}
.product__info-container>* {
    text-align: center;
}