.contact-form__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 20px;
}

.contact-form__title{    
    color: var(--color-title);
    font-size: 30px;
    font-weight: 500;
    width: 100%;    
}

.contact-form__form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 350px;
    width: 100%;

    div{
        display: flex;
        flex-direction: column;
        gap:4px;
        width: 100%;

        input, textarea{
            padding: 6px;
            border:none;
            border-bottom: 1px solid var(--color-title);
        }
    }
}

.contact-form__button{
    margin-top: 10px;
    padding: 10px 24px;
    background-color: var(--color-accent);
    border-radius: 50px;
    color: white;
}