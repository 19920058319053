:root {
    --font-main: "Montserrat", sans-serif;
    --color-white: #ffffff;
    --color-accent: #2a65e3;
    --color-title: #041a55;
    --color-subtext: #19449e;
    --color-text: #4c4f6a;
    --color-background: #f6f7f2;
    --transition: all .2s;
}
