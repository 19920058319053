.banner {
    background-color: var(--color-background);
    padding-top: 30px;

    .banner__container {
        display: flex;
        align-items: center;
        gap: 50px;
    }

    .banner__title-container {
        width: 55%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }

    .banner__title {
        color: var(--color-title);
        font-size: 76px;
        font-weight: 500;
    }

    .banner__text {
        color: var(--color-text);
        max-width: 480px;
    }

    .banner__img-container {
        width: 45%;
    }
}