.advantages {
    background-color: var(--color-background);
    padding: 50px 0;
    box-shadow: 0 3px 6px -3px rgba(0, 0, 0, .2);
    z-index: 10;

    .advantages__container {
        gap: 50px;
    }

    .advantages__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
    }

    .advantages__item {
        justify-content: flex-start;
        gap: 12px;
    }
}